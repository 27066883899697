import React from "react"
import Info2column from "../../../components/Info2column"
import QuestionAndAnswer2colmun from "../../../components/QuestionAndAnswer2colmun"
import CommentText from "../../../components/CommentText"
import ColumnGrid from "../../../components/ColumnGrid"
import CommonButton from "../../../components/CommonButton"
import ModalLink from "../../../components/ModalLink"
import LayoutInterview from "../../../components/LayoutInterview"
import RubyConverterTool from "../../../utils/RubyConverterTool"
import RubyConvert from "../../../components/RubyConvert"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Seo from "../../../components/Seo"

import IconInfo from "../../../images/icon_info.svg"
import IconPhoto from "../../../images/icon_photo.svg"
import QuestionAndAnswerToggle from "../../../components/QuestionAndAnswerToggle"
import FlagId from "../../../images/fkag_id.svg"

import FaceImg01 from "../../../images/interview/03/face_01.jpg"
import FaceImg02 from "../../../images/interview/03/face_02.jpg"

import css from "../stylesheets/interview.module.scss"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export default ({ data }) => {
  return (
    <div className={css.InterviewDetail}>
      <Seo title={`働く場所で、子どもも育つ | INTERVIEW`} />
      <LayoutInterview lang={`easyja`}>
        <Info2column id={`interview-03`} noBorder={true}>
          <div>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </div>
          <div>
            <p className={css.date}>{`2020.3.25`}</p>
            <h3 className={`mb20`}>
              <RubyConvert>{`{[働/はたら]く}{[場所/ばしょ]で、}{[子/こ]どもも}{[育/そだ]つ}`}</RubyConvert>
            </h3>
            <p>
              <RubyConvert>{`{リアさん}`}</RubyConvert>
              <small>
                <RubyConvert>{`{（33[歳/さい]）}`}</RubyConvert>
              </small>
              × <RubyConvert>{`{ウチュさん}`}</RubyConvert>
              <small>
                <RubyConvert>{`{（33[歳/さい]）}`}</RubyConvert>
              </small>{" "}
            </p>
            <p>
              <RubyConvert>{`{ウチュさんと}{リアさんは}`}</RubyConvert>
              <span className="phrase">
                <AnchorLink
                  className={`linkUnderline`}
                  to="/basic-information/kaigo/#section-2"
                >
                  EPA
                  <RubyConvert>{`[介護/かいご][福祉士/ふくしし][候補者/こうほしゃ]`}</RubyConvert>
                </AnchorLink>
                です。
              </span>
              <RubyConvert>{`{インドネシアから}{[来/き]ました。}{[初/はじ]めは}{[別々/べつべつ]の}{[施設/しせつ]で}{[働/はたら]いていました。}{[結婚/けっこん]した}{[後/あと]、}{2016[年/ねん]からは}{[一緒/いっしょ]に}{[働/はたら]いています。}{[２人/ふたり]が}{[働/はたら]いているのは、}{[千葉県/ちばけん]}{ [成田市/なりたし]に}{ある}{[特別/とくべつ][養護/ようご][老人/ろうじん]ホーム}『`}</RubyConvert>

              <ModalLink id={`facility`} className={`linkUnderline`}>
                <span style={{ color: "black" }}>
                  <RubyConvert>{`[杜/もり]の[家/いえ]なりた`}</RubyConvert>
                  <img src={IconPhoto} alt="" />
                </span>
                <div>
                  <h3>
                    <RubyConvert>{`{[働/はたら]いている}{[施設/しせつ]}`}</RubyConvert>
                  </h3>
                  <Img fluid={data.facility1.childImageSharp.fluid} />
                  <Img fluid={data.facility2.childImageSharp.fluid} />
                  <Img fluid={data.facility3.childImageSharp.fluid} />
                  <Img fluid={data.facility4.childImageSharp.fluid} />
                  <Img fluid={data.facility5.childImageSharp.fluid} />
                </div>
              </ModalLink>
              <RubyConvert>{`』{です。}{[仕事/しごと]も}{[子育/こそだ]ても}{している}{[2人/ふたり]に、}{[日本/にほん]での}{[生活/せいかつ]について}{[聞/き]きました。}`}</RubyConvert>
            </p>
            <h3>PROFILE</h3>
            <table className={`profileTable profileTableIsWideLeft`}>
              <tbody>
                <tr>
                  <th>
                    <RubyConvert>{`{[名前/なまえ]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{ウチュ}{スヘンドリさん}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[勤務先/きんむさき]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{[社会/しゃかい][福祉/ふくし][法人/ほうじん]}{[福祉/ふくし][楽団/がくだん]}{[特別/とくべつ][養護/ようご][老人/ろうじん]ホーム}{『[杜/もり]の[家/いえ]なりた』}{[介護/かいご][福祉士/ふくしし]} `}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[出身地/しゅっしんち]}`}</RubyConvert>
                  </th>
                  <td>
                    <img
                      src={FlagId}
                      alt="インドネシア国旗"
                      className={`flag`}
                    />
                    <RubyConvert>{`{インドネシア・[西/にし]ジャワ[州/しゅう]スカブミ} `}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[年齢/ねんれい]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{33[歳/さい]（1986[年/ねん][生/う]まれ）} `}</RubyConvert>
                     
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[経歴/けいれき]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{シャリフヒダヤトゥッラージャカルタ[大学/だいがく]}{ [看護/かんご][学部/がくぶ]を}{[卒業/そつぎょう]した}{[後/あと]、}{[病院/びょういん]で}{1[年/ねん][間/かん]}{ [看護/かんご][師/し]として}{[働/はたら]く}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[入国/にゅうこく]した}{ときの}{[在留資格/ざいりゅうしかく]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{EPA[介護/かいご][福祉士/ふくしし][候補者/こうほしゃ] }`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[来日/らいにち]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{2012[年/ねん]5[月/がつ] }`}</RubyConvert>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={`profileTable profileTableIsWideLeft`}>
              <tbody>
                <tr>
                  <th>
                    <RubyConvert>{`{[名前/なまえ]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{リア}{プレスティア}{アングライニさん} `}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[勤務先/きんむさき]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{[社会/しゃかい][福祉/ふくし][法人/ほうじん]}{[福祉/ふくし][楽団/がくだん]}{[特別/とくべつ][養護/ようご][老人/ろうじん]ホーム}{『[杜/もり]の[家/いえ]なりた』}{[介護/かいご][福祉士/ふくしし]} `}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[出身地/しゅっしんち]}`}</RubyConvert>
                  </th>
                  <td>
                    <img
                      src={FlagId}
                      alt="インドネシア国旗"
                      className={`flag`}
                    />
                    <RubyConvert>{`{インドネシア・ジャカルタ} `}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[年齢/ねんれい]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{33[歳/さい]（1987[年/ねん][生/う]まれ）}`}</RubyConvert>
                     
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[経歴/けいれき]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{スティクスビナワン[大学/だいがく]}{ [看護/かんご][学部/がくぶ]を}{[卒業/そつぎょう]した}{[後/あと]、}{[病院/びょういん]で}{3か[月/げつ][間/かん]}{ [看護/かんご][師/し]として}{[働/はたら]く}`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[入国/にゅうこく]した}{ときの}{[在留資格/ざいりゅうしかく]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{EPA[介護/かいご][福祉士/ふくしし][候補者/こうほしゃ] }`}</RubyConvert>
                  </td>
                </tr>
                <tr>
                  <th>
                    <RubyConvert>{`{[来日/らいにち]}`}</RubyConvert>
                  </th>
                  <td>
                    <RubyConvert>{`{2013[年/ねん]12[月/がつ] }`}</RubyConvert>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Info2column>
        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{[介護/かいご]の}{[仕事/しごと]は}{どうですか？}`
          )}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image2.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image3.childImageSharp.fluid} />
            </div>

            <div>
              <CommentText
                src={FaceImg02}
                direction={`right`}
                name={`リア さん`}
              >
                <p>
                  <RubyConvert>{`{[仕事/しごと]に}{[慣/な]れるまで}{1[年/ねん]くらい}{かかりました。}{[利用者/りようしゃ]さんが}{ごはんの}{ときに}{[口/くち]を}{あけてくれなかったり}{して、}{[大変/たいへん]だと}{[感/かん]じる}{ことが}{[多/おお]かったです。}`}</RubyConvert>
                </p>
                <p>
                  <RubyConvert>{`{いまは、}{[介護/かいご]は}{[人生/じんせい]を}{ケアする}{ことだと}{[思/おも]っています。}{[毎日/まいにち]の}{[生活/せいかつ]から}{[看取/みと]りまで、}{[一人/ひとり]ひとりを}{[大切/たいせつ]にして、}{その}{[人/ひと]に}{[必要/ひつよう]な}{ケアを}{します。}`}</RubyConvert>
                </p>
                <p>
                  <RubyConvert>{`{[利用者/りようしゃ]さんが}{[口/くち]を}{あけてくれない}{ことには}{[理由/りゆう]が}{あります。}{だから、}{[無理/むり]を}{しないで、}{ゆっくり}{[時間/じかん]を}{かける}{ことも}{あります。}{[眠/ねむ]そうな}{ときは、}{[温/あたた]かい}{タオルで}{[顔/かお]を}{ふいて、}{[目/め]が}{[覚/さ]めるように}{お[手伝/てつだ]いを}{します。}`}</RubyConvert>
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image4.childImageSharp.fluid} />
            </div>

            <div>
              <CommentText src={FaceImg01} name={`ウチュ さん`}>
                <p>
                  <RubyConvert>{`{そうですね。}{[利用者/りようしゃ]さんに}{[無理/むり]を}{させない}{ことは}{[大切/たいせつ]です。}`}</RubyConvert>
                </p>
                <p>
                  <RubyConvert>{`{[介護/かいご]は、}{[食事/しょくじ]、}{[排泄/はいせつ]、}{[入浴/にゅうよく]}{などの}{[人間/にんげん]の}{[生活/せいかつ]を}{[支/ささ]える}{[仕事/しごと]です。}{そのためには、}{[一人/ひとり]ひとりを}{よく}{[知/し]る}{ことが}{[必要/ひつよう]です。}{[一人/ひとり]ひとりの}{[状態/じょうたい]は、}{[朝礼/ちょうれい]で}{[確認/かくにん]して、}{[介護/かいご][記録/きろく]システムで}{チェックを}{しています。}`}</RubyConvert>
                </p>
                <p>
                  <RubyConvert>{`{この}{システムには、}{[利用者/りようしゃ]さん}{[一人/ひとり]ひとりの}{[介護/かいご][記録/きろく]が}{[入/はい]っています。}{パソコンや}{スマートフォンから、}{いつでも}{[書/か]いたり}{[見/み]たり}{できます。}{[体温/たいおん]や}{[血圧/けつあつ]}{などの}{バイタルサインの}{[記録/きろく]を}{[見/み]る}{ことが}{できるので、}{とても}{[便利/べんり]です。}{[大切/たいせつ]な}{[内容/ないよう]には}{[印/しるし]が}{ついているので、}{[必/かなら]ず}{チェックします。}{SNSのように}{コメントを}{[書/か]いて、}{[職員/しょくいん]や}{[利用者/りようしゃ]さんの[家族/かぞく]と}{コミュニケーションを}{[取/と]る}{ことも}{できますよ。}`}</RubyConvert>
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>

            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                <RubyConvert>{`{[利用者/りようしゃ]さんの}{[好/す]きな}{ことや}{[嫌/きら]いな}{ことの}{[記録/きろく]も}{あります。}{[写真/しゃしん]や}{ビデオを}{[見/み]る}{ことも}{できます。}{[言葉/ことば]では}{[伝/つた]わりにくい}{ことも}{[伝/つた]える}{ことが}{できるので、}{いいですね。}{わたしは、}{いつも}{[静/しず]かな}{[利用者/りようしゃ]さんが}{ムーンウォークを}{してくれたのを}{ビデオに}{[撮/と]った}{ことが}{ありますよ（[笑/わらい]）。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image5.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`ウチュ さん`}>
                <p>
                  <RubyConvert>{`{[利用者/りようしゃ]さんの}{[家族/かぞく]は、}{「[会/あ]えない}{ときの}{[様子/ようす]が}{わかって}{[嬉/うれ]しい」と}{[言/い]ってくれます。}`}</RubyConvert>
                </p>
                <p>
                  <RubyConvert>{`{わたしは}{リアさんが}{[書/か]いた}{ものに、}{「いいね」の}{ボタンを}{[押/お]す}{ことも}{ありますよ。}`}</RubyConvert>
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{[働/はたら]いている}{[施設/しせつ]は}{どんな}{ところですか？} `
          )}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image6.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image7.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText
                src={FaceImg02}
                direction={`right`}
                name={`リア さん`}
              >
                <p>
                  <RubyConvert>{`{[施設/しせつ]の}{[隣/となり]には}{[保育所/ほいくしょ]が}{あります。}{わたしたちも}{[娘/むすめ]を}{[預/あず]ける}{ことが}{できます。}`}</RubyConvert>
                </p>
              </CommentText>
              <CommentText src={FaceImg01} name={`ウチュ さん`}>
                <p>
                  <RubyConvert>{`{[保育所/ほいくしょ]の}{[子/こ]どもたちは、}{[施設/しせつ]にも}{よく}{[遊/あそ]びに}{[来/き]てくれます。}{わたしたちの}{[娘/むすめ]も}{[来/き]ます。}`}</RubyConvert>
                </p>
                <p>
                  <RubyConvert>{`{わたしが}{[担当/たんとう]した}{[利用者/りようしゃ]さんにも、}{[娘/むすめ]を}{[覚/おぼ]えてくれた}{[人/ひと]が}{いました。}{それまでは}{その[人/ひと]と}{あまり}{[話/はな]す}{ことが}{なかったのですが、}{[娘/むすめ]を}{[覚/おぼ]えてくれた}{[後/あと]は}{「[娘/むすめ]さんは}{[元気/げんき]？」と}{[聞/き]いてくれるように}{なりました。}{[子/こ]どもの}{[話/はなし]が、}{[利用者/りようしゃ]さんと}{より}{よい}{[関係/かんけい]を}{つくる}{きっかけに}{なりました。}`}</RubyConvert>
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image9.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText
                src={FaceImg02}
                direction={`right`}
                name={`リア さん`}
              >
                <p>
                  <RubyConvert>{`{[保育所/ほいくしょ]の}{ほかにも、}{[高齢/こうれい]の[人/ひと]と}{[子/こ]どもの}`}</RubyConvert>
                  <ModalLink id={`modal-1`} style={{ display: `inline-block` }}>
                    <span>
                      <RubyConvert>{`デイサービス`}</RubyConvert>
                      <img src={IconInfo} alt="" />
                    </span>
                    <div>
                      <h3>
                        <RubyConvert>{`デイサービス`}</RubyConvert>
                      </h3>
                      <p>
                        <RubyConvert>{`{[自分/じぶん]の}{[家/いえ]で}{[暮/く]らしている}{[高齢/こうれい]の}{[人/ひと]や}{[障害/しょうがい]が}{ある}{[人/ひと]が、}{[日帰/ひがえ]りで}{[通/かよ]う}{[介護/かいご]サービスです。}{[食事/しょくじ]や}{[入浴/にゅうよく]、}{[日常/にちじょう]の}{[動作/どうさ]の}{[訓練/くんれん]、}{レクリエーション}{などを}{[行/おこな]う}{ことが}{できます。}{[家/いえ]から}{[施設/しせつ]までは、}{[車/くるま]や}{バスで}{[送/おく]ります。}`}</RubyConvert>
                      </p>
                    </div>
                  </ModalLink>
                  <RubyConvert>{`{が}{[一緒/いっしょ]に}{あります。}{[月/つき]に}{2[回/かい]}{オープンする}{[美容室/びようしつ]や、}{[料理/りょうり]を}{している}{ところが}{[見/み]える}{[調理/ちょうり][室/しつ]も}{あります。}{[車/くるま]で}{パン[屋/や]さんが}{[来/き]たり、}{[月/つき]に}{1[回/かい]}{ [地域/ちいき]の}{[人/ひと]たちを}{[呼/よ]んで、}{[一緒/いっしょ]に}{ごはんを}{[食/た]べる}{ことも}{あります。}`}</RubyConvert>
                </p>
                <p>
                  <RubyConvert>{`{デイサービスでは、}{[毎日/まいにち]}`}</RubyConvert>
                  <ModalLink id={`modal-2`} style={{ display: `inline-block` }}>
                    <span>
                      <RubyConvert>{`マージャン`}</RubyConvert>
                      <img src={IconInfo} alt="" />
                    </span>
                    <div>
                      <h3>
                        <RubyConvert>{`マージャン`}</RubyConvert>
                      </h3>
                      <p>
                        <RubyConvert>{`{[中国/ちゅうごく]で}{[生/う]まれた}{テーブルゲームで、}{4[人/にん]で}{[遊/あそ]びます。}{ゲームでは}{[手/て]を}{[動/うご]かし、}{[覚/おぼ]える}{[力/ちから]も}{[必要/ひつよう]なので、}{[認知/にんち][症/しょう]の}{[予防/よぼう]にも}{[効果/こうか]が}{あると}{[言/い]われています。}`}</RubyConvert>
                      </p>
                    </div>
                  </ModalLink>
                  <RubyConvert>{`{や}{カラオケ、}{[体操/たいそう]}{などの}{レクリエーションを}{しています。}{[施設/しせつ]で}{[暮/く]らしている}{[利用者/りようしゃ]さんが}{[参加/さんか]する}{ときには、}{[準備/じゅんび]を}{したり、}{トイレに}{[連/つ]れて[行/い]ったり}{など、}{[忙/いそが]しいです（[笑/わらい]）。}`}</RubyConvert>
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image8.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`ウチュ さん`}>
                <p>
                  <RubyConvert>{`{[一人/ひとり]ひとりに}{あわせた}{ケアのためには}{する}{ことが}{たくさん}{あります。}{わたしたちの}{[施設/しせつ]には}{[障害/しょうがい]が}{ある}{[職員/しょくいん]も}{います。}{ボランティアの}{[人/ひと]も}{[来/き]てくれます。}{いろいろな}{[人/ひと]と}{[協力/きょうりょく]しながら、}{[一緒/いっしょ]に}{[働/はたら]いています。}`}</RubyConvert>
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                <RubyConvert>{`{[空/あ]いた}{[時間/じかん]には、}{ケアプランを}{つくる}{など、}{[一人/ひとり]ひとりの}{[利用者/りようしゃ]さんについて}{[考/かんが]える}{ことに}{[時間/じかん]を}{[使/つか]っていますね。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{ムスリムとして}{[働/はたら]く}{[大変/たいへん]さは}{ありますか？}`
          )}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image10.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                <RubyConvert>{`{[働/はたら]く}{[施設/しせつ]を}{[探/さが]す}{ときに、}{ヒジャブ（Hijab）を}{[被/かぶ]って}{[仕事/しごと]が}{できる}{ことを}{[条件/じょうけん]に}{しました。}{[実際/じっさい]に}{[働/はたら]いてみて、}{[大変/たいへん]な}{ことは}{[特/とく]に}{ありません。}`}</RubyConvert>
              </p>
              <p>
                <RubyConvert>{`{[男性/だんせい]の}{[介護/かいご]も}{しますが、}{みなさん}{[高齢/こうれい]なので}{[自分/じぶん]の}{お[父/とう]さんみたいな}{[感/かん]じです。}{[仕事/しごと]として}{やっている}{ことだから、}{[神様/かみさま]も}{わかってくれていると}{[思/おも]います。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                <RubyConvert>{`{[家/いえ]から}{[車/くるま]で}{20[分/ぷん]くらいの}{[場所/ばしょ]に}{モスクが}{あって、}{[毎週/まいしゅう]}{ [金曜日/きんようび]に}{[通/かよ]っています。}{モスクが}{[近/ちか]くに}{あると、}{[神様/かみさま]が}{[近/ちか]くに}{いるように}{[感/かん]じます。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                <RubyConvert>{`{[仕事/しごと][中/ちゅう]も}{お[祈/いの]りを}{します。}{[忙/いそが]しい}{ときは、}{[落/お]ち[着/つ]いてから}{「いま}{お[祈/いの]りを}{して}{いいですか？」と}{[同僚/どうりょう]に}{[聞/き]きます。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image11.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                <RubyConvert>{`{いつでも}{お[祈/いの]りを}{して}{いいのですが、}{タイミングは}{[大事/だいじ]ですね。}{お[祈/いの]りを}{する}{[時間/じかん][帯/たい]は}{1[日/にち]}{5[回/かい]}{あります。}{その}{なかで、}{[時間/じかん]を}{[見/み]つけて、}{5[分/ふん]くらい}{お[祈/いの]りを}{します。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                <RubyConvert>{`{ラマダン（Ramadan）の}{[時期/じき]には、}{[太陽/たいよう]が}{[昇/のぼ]ってから}{[沈/しず]むまで}{[食/た]べたり}{[飲/の]んだり}{しません。}{その}{[時期/じき]には、}{[体力/たいりょく]を}{[使/つか]うので}{[入浴/にゅうよく][介助/かいじょ]は}{しなくて}{いいと}{[言/い]われます。}{でも、}{[自分/じぶん]の}{[仕事/しごと]ですし、}{[大丈夫/だいじょうぶ]だと}{[思/おも]って}{[入浴/にゅうよく][介助/かいじょ]も}{しています。}{みんなは}{「リアさんが}{[倒/たお]れてしまうから、}{しなくても}{いいよ」って（[笑/わらい]）。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                <RubyConvert>{`{そう}{そう、}{すごく}{[心配/しんぱい]してくれますね。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{[日本/にほん]では}{どんな}{[生活/せいかつ]を}{していますか？}`
          )}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image12.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                <RubyConvert>{`{ウチュさんと}{わたしは}{[仕事/しごと]の}{[時間/じかん]が}{[違/ちが]います。}{[娘/むすめ]を}{[保育所/ほいくしょ]に}{[連/つ]れて[行/い]ったり}{[迎/むか]えに[行/い]ったり}{するのは、}{[2人/ふたり]の}{うち}`}</RubyConvert>
                <ModalLink id={`modal-3`} style={{ display: `inline-block` }}>
                  <span>
                    <RubyConvert>{`[遅番/おそばん]`}</RubyConvert>
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>
                      <RubyConvert>{`[遅番/おそばん]`}</RubyConvert>
                    </h3>
                    <p>
                      <RubyConvert>{`{[利用者/りようしゃ]が}{[暮/く]らしている}{[施設/しせつ]では、}{24[時間/じかん]の}{[介護/かいご]が}{[必要/ひつよう]です。}{[働/はたら]く}{[時間/じかん]は}{[法律/ほうりつ]で}{[決/き]められています。}{シフト[体制/たいせい]で、}{1か[月/げつ]ごとに}{[調整/ちょうせい]します。}{『[杜/もり]の[家/いえ]なりた』では、}{7:15～16:15（[早番/はやばん]）、}{11:00～20:00（[遅番/おそばん]）、}{18:45～8:45（[夜勤/やきん]）に}{わかれていて、}{１か[月/げつ]に}{[約/やく]5[回/かい]の}{[夜勤/やきん]が}{あります。}`}</RubyConvert>
                    </p>
                  </div>
                </ModalLink>
                <RubyConvert>{`{の}{[人/ひと]が}{します。}{お[弁当/べんとう]も}{どちらかが}{つくります。}{ウチュさんも}{[料理/りょうり]が}{できますよ。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>

          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                <RubyConvert>{`{[日本/にほん]にも、}{ムスリムが}{[食/た]べられる}{ハラルフード（Halal）を}{[売/う]っている}{スーパーマーケットが}{あります。}{たとえば、}{[最近/さいきん]}{[関東/かんとう][地方/ちほう]で}{[有名/ゆうめい]な『[肉/にく]の}{ハナマサ』。}{[近所/きんじょ]に}{あるので}{[便利/べんり]です。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                <RubyConvert>{`{[月/つき]に}{[2日/ふつか]は}{[休/やす]みたい}{[日/ひ]を}{[選/えら]べるので、}{[2人/ふたり]で}{[同/おな]じ}{[日/ひ]に}{[休/やす]みを}{[取/と]ります。}`}</RubyConvert>
              </p>
              <p>
                <RubyConvert>{`{[休/やす]みの}{[日/ひ]には、}{[娘/むすめ]と}{[公園/こうえん]で}{[遊/あそ]んだり、}{『イオン』などの}{ショッピングモールに}{[行/い]ったり}{します。}{[日本/にほん]は}{インドネシアと}{[違/ちが]って}{[四季/しき]が}{あるので、}{[春/はる]には}{[桜/さくら]、}{[秋/あき]には}{[紅葉/こうよう]を}{[見/み]に}{[行/い]く}{など、}{[季節/きせつ]ごとに}{[観光地/かんこうち]へ}{[出/で]かけるのも}{[楽/たの]しいです。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>

          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image13.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                <RubyConvert>{`{[日本/にほん]に}{いる}{インドネシア[人/じん]には、}{いろいろな}{コミュニティが}{あります。}`}</RubyConvert>
              </p>
              <p>
                <RubyConvert>{`{たとえば、}{EPAで}{[来日/らいにち]している}{インドネシア[人/じん]の}{コミュニティ『IPMI（Ikatan Perawat Muslim Indonesia）』や、}{『[在日/ざいにち]インドネシアムスリム[協会/きょうかい]（KMII）』が}{あります。}{[集/あつ]まりに}{[参加/さんか]したり、}{[旅行/りょこう]に}{[行/い]ったり}{します。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                <RubyConvert>{`{IPMIの}{イベントの}{ときは、}{200[人/にん]くらい}{[集/あつ]まりますよ。}{LINE}・{Facebook}・{Instagramで}{[情報/じょうほう][交換/こうかん]を}{しています。}`}</RubyConvert>
              </p>
              <p>
                <RubyConvert>{`{ただ、}{[近/ちか]くに}{[両親/りょうしん]や}{[親戚/しんせき]が}{いないので、}{[子/こ]どもが}{[病気/びょうき]に}{なった}{とき}{などは}{[困/こま]ります。}{[子/こ]どもを}{[預/あず]かってくれる}{[人/ひと]が}{いないのは}{[不安/ふあん]ですね。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>

          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                <RubyConvert>{`{そうですね。}{でも、}{[夫婦/ふうふ]で}{[一緒/いっしょ]に}{[暮/く]らしていますし、}{[仕事/しごと]を}{なくす}{[心配/しんぱい]が}{ないのは}{いいですよね。}{リアさんは}{[同/おな]じ}{フロアで}{[働/はたら]いているので、}{[仕事/しごと]の}{[相談/そうだん]も}{できます。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image14.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                <RubyConvert>{`{[家/いえ]でも}{[仕事/しごと]の}{[話/はなし]を}{よく}{しますよね。}{[楽/たの]しい}{ことが}{あったら、}{[2人/ふたり]で}{[盛/も]り[上/あ]がる}{ことも}{ありますよ（[笑/わらい]）。}`}</RubyConvert>
              </p>
              <p>
                <RubyConvert>{`{[結婚/けっこん]して}{5[年/ねん]に}{なりますが、}{[関係/かんけい]は}{あまり}{[変/か]わりません。}{ケンカする}{ことも}{ありますし、}{[嫌/いや]な}{[日/ひ]も}{ありますが、}{それが}{[家族/かぞく]}{という}{ものですね。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          isTall={true}
          question={RubyConverterTool(
            `{これからの}{[目標/もくひょう]を}{[教/おし]えてください。}`
          )}
        >
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                <RubyConvert>{`{わたしの}{[目標/もくひょう]は}{[永住/えいじゅう]ビザを}{[取/と]る}{ことと、}{[日本/にほん]で}{[家/いえ]を}{もつ}{ことです。}{[看護/かんご][師/し]の}{[資格/しかく]も}{[日本/にほん]で}{[取/と]りたいので、}{まずは}{[日本語/にほんご][検定/けんてい]の}{N1に}{[合格/ごうかく]したいです。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                <RubyConvert>{`{わたしは、}{いつかは}{[両親/りょうしん]が}{いる}{インドネシアに}{[帰/かえ]りたいです。}{[再来年/さらいねん]には}{N1に}{[合格/ごうかく]して、}{[通訳者/つうやくしゃ]に}{なるのが}{[夢/ゆめ]です。}{インドネシアで}{[土地/とち]を}{もっているので、}{[家/いえ]も}{[建/た]てたいですね。}`}</RubyConvert>
              </p>
              <p>
                <RubyConvert>{`{インドネシアに}{[帰/かえ]る}{ときは、}{いつも}{「リフレッシュ[休暇/きゅうか]」}{という}{[制度/せいど]を}{[使/つか]っています。}{わたしたちの}{[施設/しせつ]では}{2[年/ねん][目/め]から}{[毎年/まいとし]}{12[日/にち][以上/いじょう]の}{[休暇/きゅうか]を}{[取/と]る}{ことが}{できます。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                <RubyConvert>{`{いつか}{[家族/かぞく]で}{メッカ（Makkah）に}{[行/い]く}{という}{[夢/ゆめ]も}{あります。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`リア さん`}>
              <p>
                <RubyConvert>{`{3[歳/さい]の}{[娘/むすめ]に}{イスラム[教/きょう]について、}{どのように}{[教/おし]えるか}{[考/かんが]えています。}{[娘/むすめ]が}{[初/はじ]めて}{[話/はな]した}{[言葉/ことば]は、}{[日本語/にほんご]の}{「[嫌/いや]だ」でした（[笑/わらい]）。}{それくらい}{[日本/にほん]での}{[生活/せいかつ]に}{[慣/な]れています。}{それから、}{いま}{[2人/ふたり][目/め]の}{[子/こ]どもを}{[妊娠/にんしん]しています。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image15.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`ウチュ さん`}>
              <p>
                <RubyConvert>{`{[日本/にほん]と}{インドネシアの}{[両方/りょうほう]に}{[家/いえ]を}{[建/た]てたいのは、}{[子/こ]どもたちのためでも}{あります。}{もし}{インドネシアで}{[暮/く]らす}{ことに}{なっても、}{[日本/にほん]に}{[家/いえ]が}{あれば、}{[子/こ]どもは}{[日本/にほん]の}{[大学/だいがく]に}{[行/い]きやすくなります。}{どちらの}{[国/くに]でも}{[暮/く]らす}{ことが}{できるように}{してあげたいと}{[思/おも]っています。}`}</RubyConvert>
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <div className={`mt30`}>
          <Info2column noBorder={true}>
            <div></div>
            <div>
              <p style={{ margin: 0 }}>
                <RubyConvert>{`{[働/はたら]いている}{[施設/しせつ]}`}</RubyConvert>
              </p>
              <h3 className={`mb10`}>
                <ModalLink id={`facility`} className={`linkUnderline`}>
                  <span style={{ color: "black" }}>
                    <RubyConvert>{`[杜/もり]の[家/いえ]なりた`}</RubyConvert>
                    <img src={IconPhoto} alt="" />
                  </span>
                  <div>
                    <h3>
                      <RubyConvert>{`{[働/はたら]いている}{[施設/しせつ]}`}</RubyConvert>
                    </h3>
                    <Img fluid={data.facility1.childImageSharp.fluid} />
                    <Img fluid={data.facility2.childImageSharp.fluid} />
                    <Img fluid={data.facility3.childImageSharp.fluid} />
                    <Img fluid={data.facility4.childImageSharp.fluid} />
                    <Img fluid={data.facility5.childImageSharp.fluid} />
                  </div>
                </ModalLink>
              </h3>
              <p className={`mb40`}>
                <RubyConvert>{`{[特別/とくべつ][養護/ようご][老人/ろうじん]ホーム（[定員/ていいん]100[名/めい]）、}{ショートステイ（[定員/ていいん]10[名/めい]）、}{デイサービスなどを}{[行/おこな]っています。}{[保育所/ほいくしょ]や、}{[子/こ]どものための}{デイサービスも}{あります。}{[障害/しょうがい]が}{ある}{[人/ひと]も}{[働/はたら]いています。}{いろいろな}{[人/ひと]たちが}{[一緒/いっしょ]に}{いる}{[場所/ばしょ]です。}`}</RubyConvert>
              </p>
              <p>
                <a
                  href={`https://www.gakudan.org`}
                  rel={`noopener noreferrer`}
                  target={`_blank`}
                >
                  https://www.gakudan.org
                </a>
              </p>

              <div className="credit">
                <p>
                  Text by Tami Ono
                  <br />
                  Photo by Isamu Sakamoto
                  <br />
                  Translation by Shinsuke Hayama, Daigo Murotsu（SLOW
                  COMMUNICATION）
                </p>
              </div>

              <div className="mt40">
                <ColumnGrid>
                  <div className={css.commonButton}>
                    <CommonButton to={`/interview/2`} align={`center`}>
                      PREV
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/interview/`} align={`center`}>
                      BACK
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/interview/1`} align={`center`}>
                      NEXT
                    </CommonButton>
                  </div>
                </ColumnGrid>
              </div>
            </div>
          </Info2column>
        </div>
      </LayoutInterview>
    </div>
  )
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "interview/03/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "interview/03/02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(relativePath: { eq: "interview/03/03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image4: file(relativePath: { eq: "interview/03/04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image5: file(relativePath: { eq: "interview/03/05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image6: file(relativePath: { eq: "interview/03/06.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image7: file(relativePath: { eq: "interview/03/07.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image8: file(relativePath: { eq: "interview/03/08.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image9: file(relativePath: { eq: "interview/03/09.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image10: file(relativePath: { eq: "interview/03/10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image11: file(relativePath: { eq: "interview/03/11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image12: file(relativePath: { eq: "interview/03/12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image13: file(relativePath: { eq: "interview/03/13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image14: file(relativePath: { eq: "interview/03/14.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image15: file(relativePath: { eq: "interview/03/15.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    facility1: file(relativePath: { eq: "interview/03/facility_01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility2: file(relativePath: { eq: "interview/03/facility_02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility3: file(relativePath: { eq: "interview/03/facility_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility4: file(relativePath: { eq: "interview/03/facility_04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility5: file(relativePath: { eq: "interview/03/facility_05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
